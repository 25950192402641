import { ref } from 'vue'

export const currentCurrency = ref('')

export const currencyForServer =ref('')

export const showCurrencyModal = ref(false)

export const handleShowCurrencyModal = (): void => {
    showCurrencyModal.value = !showCurrencyModal.value
}

export const setCurrentCurrency = (currency: string): void => {
    currentCurrency.value = currency
}

export const setCurrencyForServer = (currency: string): void => {
    currencyForServer.value = currency
}