import { useDebounceFn } from '@vueuse/core'
import { partialReload } from './use-listing-search'
import { ref, watch } from 'vue'
import { FilterActiveOptions, FilterOption } from '../Pages/the-city/interfaces/Filters'
import { filterGroupType } from '@/Pages/the-city/enums/Filters'

export const filterOptions = ref<FilterActiveOptions[]>([])

export const updateActiveFiltersOptionsSingle = (category: string, data: FilterOption): void => {
    const { title, id } = data
    
    const index = filterOptions.value.findIndex(item => item.category === category)
    if (index !== -1) {
        if (filterOptions.value[index].id === id) {
            filterOptions.value.splice(index, 1)
        } else {
            filterOptions.value[index].id = id
            filterOptions.value[index].title = title
        }
    } else {
        const filter = { category, id, title }
        filterOptions.value.push(filter)
    }
}

export const updateActiveFiltersOptionsMultiple = (category: string, data: FilterOption): void => {
    const { title, id } = data
    const index = filterOptions.value.findIndex(item => item.category === category && item.id === id)
    if (index !== -1) {
        filterOptions.value.splice(index, 1)
    } else {
        const filter = { category, id, title }
        filterOptions.value.push(filter)
    }
}

export const updateActiveFiltersOptionsRange = (category: string, data: number[] | null): void => {    
    const index = filterOptions.value.findIndex(item => item.category === category)
    if (index !== -1) {
        if (!data) {
            filterOptions.value.splice(index, 1)
        } else {
            filterOptions.value[index].min = data[0]
            filterOptions.value[index].max = data[1]
        } 
    } else if (data) {
        const filter = { category, min: data[0], max: data[1] }
        filterOptions.value.push(filter)
    }
}

export const resetAllFilters = ():void => {
    filterOptions.value = []
}

export const resetSingleFilter = (data:FilterActiveOptions):void => {
    if (data.category !== filterGroupType.PRICE_RANGE) {
        const index = filterOptions.value.findIndex(item => item.category === data.category && item.id === data.id)
        filterOptions.value.splice(index, 1)
    } else {
        const indexRange = filterOptions.value.findIndex(item => item.category === data.category && item.id === data.id)
        filterOptions.value.splice(indexRange, 1)
    }
}

const resetAtFilter = ref(false)

export const setResetAtFilter = (value:boolean):void => {
    resetAtFilter.value = value
}

const debouncedApplyFilters = useDebounceFn(() => {
    partialReload()
}, 300)

watch(filterOptions, () => {
    
    if(!filterOptions.value.length && !resetAtFilter.value) {
        
        return
    }

    if(resetAtFilter.value) {
        setResetAtFilter(false)
    }

    debouncedApplyFilters()
}, { deep: true })